import { inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { AcsService } from '@konnektu/acs-data';
import { createContextLogger } from '@konnektu/helpers';
import { map, Observable, tap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AcsGuard {
  private readonly logger = createContextLogger('AcsGuard');

  private readonly acs = inject(AcsService);

  private readonly router = inject(Router);

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    return this.check(route, state);
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    return this.check(route, state);
  }

  protected check(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    return this.acs
      .hasAccess([{ Ui: { Section: `Route::${state.url}` } }])
      .pipe(
        tap((res) =>
          this.logger.info(`Check route for access - ${state.url}`, res)
        ),
        map((res) => res[0] || this.router.createUrlTree(['/']))
      );
  }
}
