import {
  Directive,
  inject,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import { AcsService, CheckAccessContext } from '@konnektu/acs-data';
import { DestroyService } from '@konnektu/helpers';
import { BehaviorSubject, of, switchMap, takeUntil } from 'rxjs';

@Directive({
  selector: '[knkRestrictAccess]',
  standalone: true,
  providers: [DestroyService]
})
export class RestrictAccessDirective implements OnInit {
  private readonly acsService = inject(AcsService);

  private readonly destroy$ = inject(DestroyService);

  private _context = new BehaviorSubject<CheckAccessContext | null>(null);

  @Input() set knkRestrictAccess(val: CheckAccessContext) {
    this._context.next(val);
  }

  constructor(
    private readonly _viewContainer: ViewContainerRef,
    private readonly _templateRef: TemplateRef<unknown>
  ) {}

  ngOnInit(): void {
    this._viewContainer.clear();

    this._context
      .pipe(
        takeUntil(this.destroy$),
        switchMap((context) =>
          context ? this.acsService.hasAccess([context]) : of([true])
        )
      )
      .subscribe((res) => {
        if (res[0]) {
          this._viewContainer.createEmbeddedView(this._templateRef);
        } else {
          this._viewContainer.clear();
        }
      });
  }
}
